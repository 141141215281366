export const AUTH_USER_TOKEN_KEY = 'ReactAmplify.TokenKey';

export const PLANS = [
	{ 
		id: 0, 
		name: 'Free', 
	},
	{ 
		id: 1, 
		name: 'Basic', 
		prices : {
			monthly: 25,
			yearly: 275,
		} 
	},
	{ 
		id: 2, 
		name: 'Premium', 
		prices : {
			monthly: 99,
			yearly: 1089,
		} 
	}
]

export const PLANS_BY_NAME = PLANS.reduce( (acc, plan) => { acc[plan.name] = plan; return acc; }, {})

