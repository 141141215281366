import React from 'react'

export default (props) => <svg
	{...props} 
	aria-hidden="true" 
	focusable="false" 
	className={props.className}
	role="img" 
	xmlns="http://www.w3.org/2000/svg" 
	viewBox={`0 0 ${props.icon.icon[0] || 0} ${props.icon.icon[1] || 0}`}
>
	<path 	
		fill="currentColor" 
		d={props.icon.icon[4]}
	></path>
</svg>