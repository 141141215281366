import React, { useContext } from "react"
import Button from "../../../components/button"
import UiStoreContext from "../../../contexts/uiStoreContext"
import { PLANS } from "../../../constants"
import tw from 'twin.macro'

const PlanCard = ({id, billsbyPlanId, billsbyRef, name, price, annualPrice, fullYearPrice, showButton, children, subscribed, billing, discount, ...rest}) => {

	const uiStore = useContext(UiStoreContext)

	const handleSelectPlan = (planId) => {
		uiStore.selectPlan(PLANS[planId])
	}

	return <div tw="flex w-full md:w-1/3 lg:w-1/3 mb-4" {...rest}>
		
		<div tw="border rounded shadow text-sm flex flex-grow flex-col">
			<h2 tw="p-2 text-center text-blue! text-xl border-b my-0! font-bold">{name}<span tw="text-base">{subscribed ? ' (subscribed)' : ''}</span></h2>
			<div tw="px-6 py-8 text-center flex-grow">
				<p tw="mb-4">
					<span tw="block text-gray-600 font-bold text-4xl">${billing === 'y' ? fullYearPrice : price}</span> <span tw="text-gray-700">per {billing === 'y' ? 'year' : 'month'}</span>
				</p>
				<strong>Includes:</strong>
				<ul tw="mb-4" css={{
					li: {
						...tw`my-2 text-sm`
					}
				}}>
					{children}
				</ul>
			</div>
			{billsbyPlanId && <a 
				href="javascript:void(0)" 
				ref={billsbyRef} 
				data-billsby-type="checkout" 
				data-billsby-product="10992" 
				data-billsby-plan={billsbyPlanId} 
				data-billsby-redirect={`${process.env.GATSBY_SITE_URL}/plans/verify`}
				tw="invisible">Subscribe</a> }
			{showButton && <Button tw="mx-2 mb-2" special="true" onClick={() => handleSelectPlan(id)}>Sign Up</Button>}
		</div>
	</div>
}

export default PlanCard