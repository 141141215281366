import React from 'react'
import tw, { styled } from 'twin.macro'
import Icon from './icon'
import {
	faSpinner
} from '@fortawesome/free-solid-svg-icons'

const StyledButton = styled.button( ({special, dark, header, disabled, skinny, center}) => ([
	tw`
	outline-none
	cursor-pointer
	inline-block
	bg-transparent
	transition-colors
	duration-200
	ease-in-out
	border
	border-transparent
	ring-1 ring-gray-700 ring-opacity-25 
	rounded-md
	text-gray-700 text-base font-medium
	px-7!
	py-2!`,
	!special && tw`
		hover:bg-gray-100
		active:bg-gray-200
	`,
	special && tw`
		bg-viking
		text-white
		ring-0
		hover:bg-viking-600
		active:bg-viking-700
	`,
	special && disabled && tw`
		bg-opacity-60
		cursor-not-allowed
	`,
	header && tw`
		text-white
		ring-white
		ring-opacity-25 ring-inset
	`,
	header && special && tw`
		bg-white
		text-blue
		hover:bg-white
	`,
	skinny && tw`
		px-1!
		py-1!
		text-sm
		font-normal
	`,
	dark && tw`
		bg-blue
		text-white
		ring-0
		hover:bg-blue-300
		active:bg-blue-200
	`,
	center && tw`justify-center`
]))

const Button = ( {children, icon, ...props} ) => {
	props.center = typeof props.center === 'undefined' ? true : props.center
	const buttonIcon = (props.spin || typeof icon !== 'undefined') ? 
		<Icon 
			tw="inline-block h-4 leading-button mr-2" 
			css={[
				props.spin && {...tw`animate-spin`}, 
				props.skinny && tw`h-3 mr-2`
			]} 
			icon={props.spin ? faSpinner : icon}
		/> : 
		null;
	return <StyledButton {...props} tw="flex items-center">{buttonIcon}{children}</StyledButton>
}

export default Button;